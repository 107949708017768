import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table } from 'antd';
import { axiosGet } from '../actions/Axios';
import HeaderMenu from './HeaderMenu';
import { StringUcFirst, FormatNumber } from '../helper/Formats';

class TransactionDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			transaction: null,
			recipient: null,
			sender: null
		};
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		this.setState({
			id
		});
		this.getTransactionDetails(id);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.setState({
				id: this.props.match.params.id,
			});
			this.getTransactionDetails(this.props.match.params.id)
		}
	}

	componentWillReceiveProps(props) {
		this.setState({
			id: props.match.params.id,
		});
		this.getTransactionDetails(this.props.match.params.id)
	}

	getTransactionDetails(id) {
		return new Promise((resolve, rejcet) => {
			axiosGet('transaction/id/' + id).then((res) => {
				let transaction = res;
				this.setState(
					{
						transaction
					},
					() => {
						this.getUserByAddress(transaction.input.sender).then((sender) => {
							this.setState({
								sender
							});
						});
						this.getUserByAddress(transaction.input.recipients[0]).then((recipient) => {
							this.setState({
								recipient
							});
						});
					}
				);
			});
		});
	}

	getUserByAddress(address) {
		return axiosGet('users/address/' + address);
	}

	renderTransactionDetail() {
		const { transaction, sender, recipient } = this.state;
		var transactions = [transaction];

		const mainColumns = [
			{
				title: 'Transaction Id',
				dataIndex: 'id',
				key: 'id',
				width: '30%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text.toUpperCase()}</p>
						</div>
					);
				}
			},
			{
				title: 'Source',
				dataIndex: 'source_id',
				key: 'source_id',
				align: 'center',
				render: (text, record) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.source}</p>
						</div>
					);
				}
			},

			{
				title: 'Description',
				dataIndex: 'description',
				key: 'description+id',
				align: 'center',
				render: (text, record) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
						</div>
					);
				}
			},
			{
				title: 'Sender Phone',
				dataIndex: 'sender_number',
				key: 'sender_number',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<Link to={`/wallet-info/${record.input.sender}`}>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
						</Link>
					);
				}
			},
			{
				title: 'Recipeint Phone',
				dataIndex: 'recipient_number',
				key: 'recipient_number',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<Link to={`/wallet-info/${record.input.recipient_numbers[text]}`}>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
							</Link>
							{/* <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p> */}
						</div>
					);
				}
			},
			{
				title: 'Date',
				dataIndex: 'input',
				key: 'input+id',
				width: '18%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>
								{moment(text.timestamp).format('DD-MM-YYYY hh:mm:ss a')}
							</p>
						</div>
					);
				}
			},
			{
				title: 'Payment Amount',
				dataIndex: 'amount',
				key: 'amount+id',
				align: 'center',
				render: (text, record) => {
					let Total = 0;
					//text.map(t => Total += parseFloat(t.amount))
					for (let i = 0; i < record.input.recipients.length; i++) {
						Total += parseFloat(record.outputMap[record.input.recipients[i]]);
					}
					return (
						<div style={{ width: '100%', textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{FormatNumber(Total)}</p>
						</div>
					);
				}
			}
		];
		const secondColumns = [
			{
				title: 'User',
				dataIndex: 'user_firstname',
				key: 'user_firstname+id',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<Link to={`/wallet-info/${record.user_wallet_address}`}>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text + ' ' + record.user_lastname}</p>
							</Link>
							{/* <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text + ' ' + record.user_lastname}</p> */}
						</div>
					);
				}
			},
			{
				title: 'User Type',
				dataIndex: 'user_type_name',
				key: 'user_type_name+id',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
						</div>
					);
				}
			},
			{
				title: 'Account Type',
				dataIndex: 'account_type',
				key: 'account_type+id',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
						</div>
					);
				}
			},
			// {
			// 	title: 'Balance',
			// 	dataIndex: 'balance',
			// 	key: 'balance+id',
			// 	width: '12%',
			// 	align: 'center',
			// 	render: (text, record) => {
			// 		return (
			// 			<div>
			// 				<p style={{ marginBottom: 0, paddingBottom: 0 }}>{FormatNumber(text)}</p>
			// 			</div>
			// 		);
			// 	}
			// },
			{
				title: 'Agent',
				dataIndex: 'is_agent',
				key: 'id+is_agent',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text ? text : 0}</p>
						</div>
					);
				}
			}
		];
		const thirdColumns = [
			{
				title: 'Sender Wallet',
				dataIndex: 'input',
				key: 'input+sender_number',
				width: '44%',
				align: 'center',
				render: (text, record) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>
								{text.sender ? text.sender.toUpperCase() : ''}
							</p>
						</div>
					);
				}
			},
			{
				title: 'Receiver Wallet',
				dataIndex: 'input',
				key: 'input+recipient_number',
				width: '44%',
				align: 'center',
				render: (text, record) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>
								{text.recipients.length > 0 ? text.recipients[0].toUpperCase() : ''}
							</p>
						</div>
					);
				}
			}
		];

		return (
			<div style={{ padding: 20 }}>
				<div>
					<h1>Transaction</h1>
					<Table
						scroll={{ y: 530 }}
						dataSource={transactions}
						rowKey={(record) => record.id}
						columns={mainColumns}
						showHeader={true}
						bordered
						pagination={false}
					/>
				</div>
				{sender && sender.user_id ? (
					<div style={{ marginTop: 50 }}>
						<h1>Sender</h1>
						<Table
							scroll={{ y: 530 }}
							dataSource={[sender]}
							rowKey={(record) => record.user_id}
							columns={secondColumns}
							showHeader={true}
							bordered
							pagination={false}
						/>
					</div>
				) : null}

				{recipient && recipient.user_id ? (
					<div style={{ marginTop: 50 }}>
						<h1>Receiver</h1>
						<Table
							scroll={{ y: 530 }}
							dataSource={[recipient]}
							rowKey={(record) => record.user_id}
							columns={secondColumns}
							showHeader={true}
							bordered
							pagination={false}
						/>
					</div>
				) : null}

				<div style={{ marginTop: 50 }}>
					<Table
						scroll={{ y: 530 }}
						dataSource={transactions}
						rowKey={(record) => record.id + record.sender}
						columns={thirdColumns}
						showHeader={true}
						bordered
						pagination={false}
					/>
				</div>
			</div>
		);
	}

	render() {
		const { transaction, id } = this.state;
		return (
			<div>
				<HeaderMenu selectedKey={3} />

				{transaction && id ? (
					<div>
						<h3 style={{ padding: 20, borderBottom: '2px solid grey', borderTop: '2px solid grey' }}>
							Transaction ID : {id.toUpperCase()}
						</h3>
						{this.renderTransactionDetail()}
					</div>
				) : null}
			</div>
		);
	}
}

export default TransactionDetail;
