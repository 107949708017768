import Axios from 'axios';
import { store } from '../index';
const ADMIN_API_URL = process.env.REACT_APP_LEZZOO_PAY_ADMIN_API || 'http://localhost:4020/';
export function axiosGet(url, params = {}) {
	return new Promise(async (resolve, reject) => {
		const { admin } = store.getState();
		var config = {
			headers: {
				'jwt-token': admin && admin.token ? admin.token : ''
			}
		};
		await Axios.get(ADMIN_API_URL + url, config)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function axiosPost(url, body = {}) {
	return new Promise(async (resolve, reject) => {
		const { admin } = store.getState();

		var config = {
			headers: {
				'jwt-token': admin && admin.token ? admin.token : ''
			}
		};
		await Axios.post(ADMIN_API_URL + url, body, config)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function axiosPut(url, body = {}) {
	return new Promise(async (resolve, reject) => {
		const { admin } = store.getState();
		var config = {
			headers: {
				'jwt-token': admin && admin.token ? admin.token : ''
			}
		};
		await Axios.put(ADMIN_API_URL + url, body, config)
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
