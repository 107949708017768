
export const actionTypes = {
	RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
	ADMIN_DATA_SUCCESS: 'ADMIN_DATA_SUCCESS',
	ADMIN_DATA_LOADING: 'ADMIN_DATA_LOADING',
	ADMIN_DATA_ERROR: 'ADMIN_DATA_ERROR',
	ADMIN_LOGOUT_SUCCESS: 'ADMIN_LOGOUT_SUCCESS',
	// ADMIN_LOGOUT_LOADING: 'ADMIN_LOGOUT_LOADING',
	// ADMIN_LOGOUT_ERROR: 'ADMIN_LOGOUT_ERROR',
};

// Resets the currently visible error message.
export const triggerReducer = (type, extra = {}) => ({
	type: type,
	...extra
})
