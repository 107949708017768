//to make First leeter upper
export function StringUcFirst(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string;
	}
}

//to format amount readable form
export function FormatNumber(number) {
	return parseFloat(number).toLocaleString('en', {
		maximumSignificantDigits: 21
	});
}
