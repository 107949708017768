import { combineReducers } from 'redux';
import { admin } from './adminReducers';
import { actionTypes } from '../actions';
import { message } from 'antd';


// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
	const { type, error } = action;

	if (type === actionTypes.RESET_ERROR_MESSAGE) {
		return null;
	} else if (error) {
		message.error(error);
		return error;
	}

	return state;
};

const rootReducer = combineReducers({
	errorMessage,
	admin,
});

export default rootReducer;