import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { loginAdmin } from '../actions/adminAction';
import { Form, Icon, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';

class Login extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			chains: [],
			admin_username: '',
			admin_password: ''
		};
	}

	handleUsernameChange = (e) => {
		let admin_username = e.target.value;
		this.setState({
			admin_username
		});
	};
	handlePasswordChange = (e) => {
		let admin_password = e.target.value;
		this.setState({
			admin_password
		});
	};

	render() {
		if (this.props.admin && this.props.admin.admin_id && this.props.admin.token) {
			return <Redirect to="/home" />;
		} else {
			return (
				<div style={{ textAlign: 'center', paddingTop: '5%' }}>
					<h1>Admin Login</h1>
					<Form className="login-form" style={{ textAlign: 'center', padding: '0% 35%' }}>
						<Form.Item>
							<Input
								prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder="Username"
								onChange={this.handleUsernameChange}
							/>
						</Form.Item>
						<Form.Item>
							<Input
								prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
								type="password"
								placeholder="Password"
								onChange={this.handlePasswordChange}
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="login-form-button"
								onClick={() =>
									this.props
										.loginAdmin(this.state.admin_username, this.state.admin_password)
										.then(() => {})}
							>
								Login
							</Button>
						</Form.Item>
					</Form>
				</div>
			);
		}
	}
}
const mapStateToProps = (state, ownProps) => ({
	errorMessage: state.errorMessage,
	admin: state.admin
});

export default connect(mapStateToProps, {
	loginAdmin
})(Login);
