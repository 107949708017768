import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';

import Login from './components/Login';
import Logout from './components/Logout';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import BlockchainList from './components/BlockchainList';
import AdminList from './components/AdminList';
import UserList from './components/UserList';
import TransactionList from './components/TransactionList';
import BlockDetail from './components/BlockDetail';
import WalletList from './components/WalletList';
import WalletInfo from './components/WalletInfo';
import CreateTransaction from './components/CreateTransaction';
import TransactionDetail from './components/TransactionDetail';
import PrivateRoute from './helper/PrivateRoute';
import { initialLogin } from './actions/adminAction';

class App extends PureComponent {
	componentDidMount() {
		this.props.initialLogin();
	}
	render() {
		// var admin = JSON.parse(localStorage.getItem('admin'))
		return (
			<Router>
				<div className="App-nav">
					{/* {admin && admin.admin_id && <Redirect to='/dashboard' />} */}
					<Switch>
						<Route exact path="/" component={Login} />
						<PrivateRoute exact path="/logout" component={Logout} />
						<PrivateRoute exact path="/home" component={Home} />
						<PrivateRoute exact path="/dashboard" component={Dashboard} />
						<PrivateRoute exact path="/blocks" component={BlockchainList} />
						<PrivateRoute exact path="/admins" component={AdminList} />
						<PrivateRoute exact path="/users" component={UserList} />
						<PrivateRoute exact path="/transactions" component={TransactionList} />
						<PrivateRoute exact path="/walletList" component={WalletList} />
						<PrivateRoute
							exact
							path="/wallet-info/:address"
							component={WalletInfo}
						/>
						<PrivateRoute exact path="/create-transaction" component={CreateTransaction} />
						<PrivateRoute exact path="/block-detail/:hash" component={BlockDetail} />
						<PrivateRoute exact path="/transaction-detail/:id" component={TransactionDetail} />
					</Switch>
				</div>
			</Router>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	errorMessage: state.errorMessage,
	admin: state.admin
});

export default connect(mapStateToProps, { initialLogin })(App);
