import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { ExportToCsv } from 'export-to-csv'
import { axiosGet, axiosPost } from '../actions/Axios'
import { Row, Col, Input, Button, Table, DatePicker } from 'antd'
import moment from 'moment'
import HeaderMenu from './HeaderMenu'
import { StringUcFirst, FormatNumber } from '../helper/Formats'

const { RangePicker } = DatePicker;

function checkSearch(data, searchQuery) {
  if (searchQuery && searchQuery.length > 2) {
    const stringifyOrder = JSON.stringify(data);
    const stringifyOrderLower = stringifyOrder.toLowerCase();
    const findQuery = stringifyOrderLower.indexOf(searchQuery.toLowerCase());

    return findQuery > -1;
  } else {
    return true;
  }
}
const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: false,
  useKeysAsHeaders: true,
};

const csvExporter = new ExportToCsv(options);

class TransactionList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selected_date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      transactions: [],
      searchQuery: null,
      totalCount: 0,
      pagination: { current: 0, pageSize: 0 },
      offset: 0,
      limit: 10
    }
  }

  componentDidMount() {
    this.fetchData()
    //this.getTransactionList()
  }

  getTransactionList() {
    const { offset, limit, searchQuery, selected_date } = this.state
    const start = selected_date[0] ? selected_date[0].toString() : moment().format('YYYY-MM-DD').toString();
    const end = selected_date[1] ? selected_date[1].toString() : moment().format('YYYY-MM-DD').toString();
    axiosPost('transaction/all', { offset, limit, searchQuery, start, end }).then((res) => {
      let { transactions, totalCount } = res;
      this.setState({
        transactions,
        totalCount
      })
    });
  }
  fetchData() {
    this.getTransactionList()
    // setTimeout(() => {
    //   this.fetchData();
    // }, 3000);
  }

  // handleTableChange = (pagination, filters, sorter) => {
  //   const pager = { ...this.state.pagination };
  //   pager.current = pagination.current;
  //   pager.pageSize = pagination.pageSize
  //   var offset = (pagination.pageSize * (pagination.current - 1))
  //   var limit = pagination.pageSize
  //   this.setState({
  //     pagination: pager,
  //     offset,
  //     limit
  //   }, () => {
  //     this.getTransactionList()
  //   });
  // };

  exportDataToCSV() {
    const { transactions } = this.state
    var exportData = [];
    transactions.length > 0 && transactions.map((t, index) => {
      exportData.push({
        Index: index + 1,
        'Transaction Id': t.id,
        'Source Id': t.id !== '1' ? t.source_id : '',
        'Sender Phone': t.id !== '1' ? t.sender_number : 'Genesis',
        'Recipient Phone': t.id !== '1' ? t.recipient_number : 'Genesis',
        Date: t.id !== '1' ? moment(t.input.timestamp).format('DD-MM-YYYY hh:mm:ss a') : 'Genesis',
        Amount: t.id !== '1' ? parseFloat(t.input.amount) - parseFloat(t.outputMap[t.input.sender]) : 'Genesis',
        Source: t.source,
        Description: t.description
      })
      return true
    })
    csvExporter.generateCsv(exportData)
  }

  renderTransactions() {
    const { transactions, pagination } = this.state;
    const { current, pageSize } = pagination
    const columns = [
      {
        title: 'Index',
        dataIndex: 'id',
        key: 'id+recipient_number',
        width: '7%',
        align: 'center',
        render: (text, record) => {
          return (
            <div>
              <p>{(transactions.findIndex((t) => t.id === text) + 1) + ((current - 1) * pageSize)}</p>
            </div>
          );
        }
      },
      {
        title: 'Transaction Id',
        dataIndex: 'id',
        key: 'id',
        width: '31%',
        align: 'center',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Link to={`/transaction-detail/${text}`} >{text.toUpperCase()}</Link>
              </p>
            </div>
          );
        }
      },
      {
        title: 'Sender Phone',
        dataIndex: 'sender_number',
        key: 'sender_number',
        width: '12%',
        align: 'center',
        render: (text, record) => {
          return (
            <Link to={`/wallet-info/${record.input.sender}`}>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.user_firstname + " " + record.user_lastname}</p>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
            </Link>
          );
        }
      },
      {
        title: 'Recipeint Phone',
        dataIndex: 'recipient_number',
        key: 'recipient_number',
        width: '12%',
        align: 'center',
        render: (text, record) => {
          return (
            <div>
              <Link to={`/wallet-info/${record.input.recipient_numbers[text] ? record.input.recipient_numbers[text] : record.input.recipients[0]}`}>
                <p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.receiver_user_firstname + " " + record.receiver_user_lastname}</p>
                <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
              </Link>
            </div>
          );
        }
      },
      {
        title: 'Source',
        dataIndex: 'source_id',
        key: 'source_id',
        width: '12%',
        align: 'center',
        render: (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.source}</p>
            </div>
          );
        }
      },
      {
        title: 'Date',
        dataIndex: 'input',
        key: 'input',
        align: 'center',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{moment(text.timestamp).format('DD-MM-YYYY hh:mm:ss a')}</p>
            </div>
          );
        }
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: '10%',
        align: 'center',
        render: (text, record) => {
          let Total = 0;
          //text.map(t => Total += parseFloat(t.amount))
          for (let i = 0; i < record.input.recipients.length; i++) {
            Total += parseFloat(record.outputMap[record.input.recipients[i]]);
          }
          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{FormatNumber(Total)}</p>
            </div>
          );
        }
      },
    ]

    return (
      <Table
        scroll={{ y: 510 }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          position: 'bottom',
          pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
          total: this.state.totalCount,
        }}
        onChange={this.handleTableChange}
        dataSource={transactions}
        rowKey={record => record.id}
        columns={columns}
        showHeader={true}
        bordered
      />
    )
  }
  onSearchData(searchQuery) {
    // var { transactions } = this.state
    // if (searchQuery && searchQuery.length > 0) {
    //   transactions = _.filter(transactions, function (o) {
    //     return checkSearch(o, searchQuery);
    //   })
    // }

    // this.setState({
    //   transactions
    // })
    this.setState({
      searchQuery
    }, () => {
      this.getTransactionList()
    });
  }
  handleDateChange(dateStrings) {
    const selected_date = dateStrings.length > 0 ? dateStrings : [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];

    this.setState(
      {
        selected_date
      },
      () => {
        this.getTransactionList();
      }
    );
  }
  renderDateFilter() {
    return (
      <RangePicker
        style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
        format="YYYY-MM-DD"
        disabledDate={this.disabledDate}
        defaultValue={[moment(), moment()]}
        ranges={{
          Today: [moment(), moment()],
          Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment()],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
          ]
        }}
        onChange={(date, dateStrings) => this.handleDateChange(dateStrings)}
      />
    );
  }
  render() {
    return (
      <div>
        <HeaderMenu selectedKey={'3'} />
        <div style={{ margin: 30 }}>
          <Row gutter={16}>
            <Col span={7}>
              <h1>Transaction List : {this.state.totalCount}</h1>
            </Col>
            <Col span={5}>
              {this.renderDateFilter()}
            </Col>
            <Col span={8}>
              <Input.Search
                placeholder="Search Source id, Source, Description, Amount..."
                onSearch={(value) => this.onSearchData(value)}
                enterButton="Search"
                size="default"
                allowClear
              />
            </Col>
            <Col span={2}>
              <Button type="primary" onClick={() => { this.exportDataToCSV() }}>
                Export
              </Button>
            </Col>
          </Row>
          {
            this.renderTransactions()
          }
        </div>
      </div>
    )
  }
}

export default TransactionList;