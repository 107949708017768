import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { axiosGet, axiosPost } from '../actions/Axios'
import { Row, Col, Button, Input, Table, DatePicker } from 'antd'
import HeaderMenu from './HeaderMenu'
import { StringUcFirst, FormatNumber } from '../helper/Formats'
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';

function checkSearch(data, searchQuery) {
  if (searchQuery && searchQuery.length > 2) {
    const stringifyOrder = JSON.stringify(data);
    const stringifyOrderLower = stringifyOrder.toLowerCase();
    const findQuery = stringifyOrderLower.indexOf(searchQuery.toLowerCase());

    return findQuery > -1;
  } else {
    return true;
  }
}
const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: false,
  useKeysAsHeaders: true
};

const csvExporter = new ExportToCsv(options);

class UserList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      showUsers: [],
      searchQuery: null,
      pagination: { current: 0, pageSize: 0 },
      offset: 0,
      limit: 10,
      totalUsers: 0,
      date: moment().format('YYYY-MM-DD')
    }
  }

  componentDidMount() {
    this.getUsersList()
  }
  getUsersList() {
    const { offset, limit, date, searchQuery } = this.state;

    axiosPost('users/all', { offset, limit, date, searchQuery }).then((res) => {
      var users = res.users
      var showUsers = res.users
      var totalUsers = res.totalUsers.count
      this.setState({
        showUsers,
        users,
        totalUsers
      })
    })
  }

  permitRevokeAgent(user_phone_no, is_agent) {
    axiosPost('users/agent', { user_phone_no, is_agent })
      .then((res) => {
        alert("User's Agent status changed")
        this.getUsersList()
      })
      .catch((err) => {
        console.log("Something Went wrong ", err)
      })
  }

  onSearchData(searchQuery) {
    const { users } = this.state
    var showUsers = users
    this.setState({
      offset: 0,
      showUsers,
      searchQuery
    }, () => {
      this.getUsersList()
    })
    // if (searchQuery && searchQuery.length > 0) {
    //   showUsers = _.filter(users, function (o) {
    //     return checkSearch(o, searchQuery);
    //   })
    // }
  }

  exportDataToCSV() {
    const { users } = this.state;
    var exportData = [];
    if (users.length > 0) {
      users.map((u, index) => {
        exportData.push({
          Index: index + 1,
          Name: u.user_firstname + ' ' + u.user_lastname,
          "User Phone": u.user_phone_no,
          "User End Balance": u.balance,
          "Type": u.user_type_name,
          "Date": moment().format('YYYY-MM-DD')
        });
        return true;
      })
      csvExporter.generateCsv(exportData);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    var offset = (pagination.pageSize * (pagination.current - 1))
    var limit = pagination.pageSize
    this.setState({
      pagination: pager,
      offset,
      limit
    }, () => {
      this.getUsersList()
    });
  };

  stringUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderUsersList() {
    const { showUsers, pagination } = this.state;
    const { current, pageSize } = pagination
    const columns = [
      {
        title: 'Index',
        dataIndex: 'user_wallet_address',
        key: 'user_wallet_address+user_phone_no',
        width: '5%',
        render: (text, record) => {
          return (
            <div>
              <p>{(showUsers.findIndex((u) => u.user_wallet_address === text) + 1) + ((current - 1) * pageSize)}</p>
            </div>
          );
        }
      },
      {
        title: 'User',
        dataIndex: 'user_firstname',
        key: 'user_firstname',
        width: '10%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Link to={`/wallet-info/${record.user_wallet_address}`}>
                  {text + " " + record.user_lastname}
                </Link>
              </p>
            </div>
          );
        }
      },
      {
        title: 'User Phone',
        dataIndex: 'user_phone_no',
        key: 'user_phone_no',
        width: '12%',
        render: (text, record) => {
          return (
            <p style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Link to={`/wallet-info/${record.user_wallet_address}`}>
                {text}
              </Link>
            </p>
          );
        }
      },
      {
        title: 'Wallet',
        dataIndex: 'user_wallet_address',
        key: 'user_wallet_address',
        width: '43%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Link to={`/wallet-info/${text}`}>
                  {text}
                </Link>
              </p>
            </div>
          );
        }
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance+user_id',
        width: '8%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{FormatNumber(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'User Type',
        dataIndex: 'user_type_name',
        key: 'user_type_name',
        width: '8%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Wallet Type',
        dataIndex: 'account_type',
        key: 'account_type',
        width: '7%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Agent',
        dataIndex: 'is_agent',
        key: 'is_agent',
        width: '7%',
        render: (text, record) => {
          return (
            parseInt(text) === 0 ?
              <Button
                type="primary"
                size="small"
                onClick={() => this.permitRevokeAgent(record.user_phone_no, 1)}
              >
                Permit
                </Button>
              :
              <Button
                type="danger"
                size="small"
                onClick={() => this.permitRevokeAgent(record.user_phone_no, 0)}
              >
                Revoke
            </Button>
          );
        }
      }
    ]

    return (
      <Table
        scroll={{ y: 480 }}
        dataSource={showUsers}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          position: 'bottom',
          pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
          total: this.state.totalUsers,
        }}
        rowKey={(record) => record.user_wallet_address}
        columns={columns}
        showHeader={true}
        onChange={this.handleTableChange}
        bordered
      />
    )
  }
  onChange = (d, dateString) => {
    let date = dateString ? dateString : moment().format('YYYY-MM-DD')
    this.setState({
      date
    }, () => {
      this.getUsersList()
    })
  }
  render() {
    return (
      <div>
        <HeaderMenu selectedKey={'6'} />
        <div style={{ margin: 30 }}>
          <Row gutter={16}>
            <Col span={9}>
              <h1>Users List : {this.state.totalUsers ? this.state.totalUsers : null}</h1>
            </Col>
            <Col span={4}>
              <DatePicker
                onChange={this.onChange}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={8}>
              <Input.Search
                placeholder="Search User, User Phone, Wallet, User Type"
                onSearch={(value) => this.onSearchData(value)}
                enterButton="Search"
                size="default"
                allowClear
              />
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                onClick={() => {
                  this.exportDataToCSV();
                }}
              >
                Export
				    	</Button>
            </Col>
          </Row>
          {
            this.renderUsersList()
          }
        </div>
      </div >
    )
  }
}

export default UserList;