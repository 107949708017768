import { axiosPost } from './Axios';
import { triggerReducer, actionTypes } from '.';
import { message } from 'antd';

export const initialLogin = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try {
			let storageAdmin = await localStorage.getItem('admin');
			storageAdmin = await JSON.parse(storageAdmin);
			dispatch(
				triggerReducer(actionTypes.ADMIN_DATA_SUCCESS, {
					admin: storageAdmin
				})
			);
			resolve(storageAdmin);
		} catch (err) {
			reject();
		}
	});
};

export const loginAdmin = (admin_username, admin_password) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		axiosPost('login/admin', { admin_username, admin_password })
			.then((res) => {
				if (res && res.admin_id) {
					dispatch(
						triggerReducer(actionTypes.ADMIN_DATA_SUCCESS, {
							admin: res
						})
					);
					localStorage.setItem('admin', JSON.stringify(res));

					resolve(res);
				} else {
					dispatch(triggerReducer(actionTypes.ADMIN_DATA_ERROR, { error: 'Wrong username/Password' }));
					reject(res);
				}
			})
			.catch((err) => {
				if (err.response && err.response.data && err.response.data.message === 'Wrong password or username') {
					message.warning('Wrong username/Password');
				} else {
					message.error('Error reaching the server, please try again!');
				}
				reject(err);
			});
	});
};

export const logoutAdmin = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		dispatch(
			triggerReducer(actionTypes.ADMIN_LOGOUT_SUCCESS, {
				admin: null
			})
		);
		localStorage.removeItem('admin');
	});
};
