import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import { Statistic, Row, Col, Button, Card } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { axiosGet, axiosPost } from '../actions/Axios';
import _ from 'lodash';

class LezzooPayComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			balance: 0,
			sent: 0,
			sentBalance: 0,
			received: 0,
			receivedBalance: 0,
			transactions: 0,
			transactionsBalance: 0,
			walletStatus: 1,
			walletAddress: false,
			buttonLoading: false,
			offset: 0,
			limit: 1000
		};
	}

	componentDidMount() {
		const { walletAddress } = this.props;
		this.setState({
			walletAddress
		}, () => {
			this.getTransactionDetails()
		})
	}

	getTransactionDetails() {
		const { offset, limit, walletAddress } = this.state
		let sentBalance = 0, receivedBalance = 0;
		axiosPost('transaction/address', { address: walletAddress, offset, limit }).then((res) => {
			if (res && typeof res.transactions !== 'undefined') {
				let { transactions, balance } = res;

				let sent = _.filter(transactions, function (o) {
					return o.input.sender === walletAddress;
				});

				let received = _.filter(transactions, function (o) {
					return o.input.recipients.includes(walletAddress);
				});
				for (let transaction of sent) {
					const recipients = transaction.input.recipients
					for (let i = 0; i < recipients.length; i++) {
						let recipient = recipients[i];
						sentBalance = sentBalance + parseFloat(transaction.outputMap[recipient]);
					}
				}
				for (let transaction of received) {
					receivedBalance = receivedBalance + parseFloat(transaction.outputMap[walletAddress]);
				}

				this.setState({
					transactions,
					balance,
					sent: sent.length || 0,
					received: received.length || 0,
					sentBalance,
					receivedBalance
				});
			}
		})
			.catch((error) => {
				console.log('error', error);
				// reject(error);
			});
	}
	// createWallet() {
	// 	this.setState({
	// 		buttonLoading: true
	// 	});
	// 	const { customer_id } = this.state;

	// 	this.props.createWallet(customer_id).then((data) => {
	// 		console.log('createWallet', data);

	// 		this.setState(
	// 			{
	// 				balance: data.balance,
	// 				walletAddress: data.address,
	// 				walletStatus: 1,
	// 				buttonLoading: false
	// 			},
	// 			() => {
	// 				this.getWalletDetails();
	// 			}
	// 		);
	// 	});
	// }

	// enableWallet() {
	// 	this.setState({
	// 		buttonLoading: true
	// 	});
	// 	const { customer_id } = this.state;

	// 	this.props.enableWallet(customer_id).then((data) => {
	// 		console.log('enableWallet', data);

	// 		this.setState(
	// 			{
	// 				walletStatus: 1,
	// 				buttonLoading: false
	// 			},
	// 			() => {
	// 				this.getWalletDetails();
	// 			}
	// 		);
	// 	});
	// }

	// disableWallet() {
	// 	this.setState({
	// 		buttonLoading: true
	// 	});
	// 	const { customer_id } = this.state;

	// 	this.props.disableWallet(customer_id).then((data) => {
	// 		console.log('disableWallet', data);

	// 		this.setState({
	// 			walletStatus: 0,
	// 			buttonLoading: false
	// 		});
	// 	});
	// }

	// getWalletDetails() {
	// 	const { walletAddress } = this.state;

	// 	this.props.getWallet(walletAddress).then((data) => {
	// 		console.log('balance', data);

	// 		this.setState({
	// 			balance: data.balance
	// 		});
	// 	});

	// 	this.props.getWalletSentTransactions(walletAddress).then((data) => {
	// 		console.log('sent', data);
	// 		this.setState({
	// 			sent: data.transactions.length || 0,
	// 			sentBalance: data.total || 0
	// 		});
	// 	});

	// 	this.props.getWalletReceivedTransactions(walletAddress).then((data) => {
	// 		console.log('received', data);

	// 		this.setState({
	// 			received: data.transactions.length || 0,
	// 			receivedBalance: data.total || 0
	// 		});
	// 	});
	// }

	renderWallet() {
		const { balance, sent, received, sentBalance, receivedBalance } = this.state;
		return (
			<div>
				<Row style={{paddingLeft:'10%', paddingRight:'10%', paddingBottom:10}}>
					<Col span={6}>
						<Statistic
							title="Balance"
							value={balance}
							valueStyle={{ color: '#000000', fontSize: 14 }}
							suffix="IQD"
						/>
					</Col>

					<Col span={6}>
						<Statistic
							title={`Transactions (${received + sent})`}
							value={receivedBalance + sentBalance}
							valueStyle={{ color: '#000', fontSize: 14 }}
							prefix={<ArrowDownOutlined />}
							suffix="IQD"
						/>
					</Col>
					<Col span={6}>
						<Statistic
							title={`Received (${received})`}
							value={receivedBalance}
							valueStyle={{ color: '#3f8600', fontSize: 14 }}
							prefix={<ArrowDownOutlined />}
							suffix="IQD"
						/>
					</Col>
					<Col span={6}>
						<Statistic
							title={`Sent (${sent})`}
							value={sentBalance}
							valueStyle={{ color: '#cf1322', fontSize: 14 }}
							prefix={<ArrowUpOutlined />}
							suffix="IQD"
						/>
					</Col>
				</Row>
				{/* <hr />
				<Row style={{paddingLeft:'20%', paddingRight:'20%'}}>
				
				</Row> */}
			</div>
		);
	}
	render() {
		const { walletStatus, walletAddress, buttonLoading, sent, received } = this.state;
		return (
			<div>
				<hr />
				{/* <Row>
					<Col span={12}>
						{walletAddress ? walletStatus === 1 ? (
							<Button
								// onClick={() => {
								// 	this.disableWallet();
								// }}
								loading={buttonLoading}
								disabled={buttonLoading}
								size="small"
								type="danger"
							>
								Disable Wallet
							</Button>
						) : (
								<Button
									// onClick={() => {
									// 	this.enableWallet();
									// }}
									loading={buttonLoading}
									disabled={buttonLoading}
									size="small"
									type="primary"
								>
									Enable Wallet
								</Button>
							) : (
								<Button
									// onClick={() => {
									// 	this.createWallet();
									// }}
									loading={buttonLoading}
									disabled={buttonLoading}
									size="small"
									type="default"
								>
									Create Wallet
								</Button>
							)}
					</Col>
				</Row> */}

				{this.renderWallet()}
			</div>
		);
	}
}

// LezzooPayComponent.propTypes = {
// 	customer_id: PropTypes.number,
// 	customer_wallet_address: PropTypes.string
// };


const mapStateToProps = (state, ownProps) => {
	const states = {
		errorMessage: state.errorMessage,
		admin: state.admin
	};
	return states;
};

export default connect(mapStateToProps, {})(LezzooPayComponent);
