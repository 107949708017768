import React, { PureComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { axiosGet, axiosPost } from '../actions/Axios';
import HeaderMenu from './HeaderMenu';
import { StringUcFirst, FormatNumber } from '../helper/Formats';
import { Row, Col, Tabs, Form, Icon, Input, Button, Table, Select } from 'antd';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import LezzooPayComponent from './LezzooPayComponent';

const { TabPane } = Tabs;
const { Option } = Select;

const options = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	useTextFile: false,
	useBom: false,
	useKeysAsHeaders: true
};

const csvExporter = new ExportToCsv(options);

class WalletInfo extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			address: this.props.match.params.address,
			user_firstname: null,
			user_lastname: null,
			user_phone_no: null,
			transactions: [],
			sent: [],
			received: [],
			amount: '',
			receiverAddress: '',
			description: '',
			pagination: {},
			offset: 0,
			limit: 10,
			currentBalance: 0,
			date: moment().format('YYYY-MM-DD'),
			exportData: [],
			user_list: []
		};
	}

	componentDidMount() {
		const { address } = this.props.match.params;
		this.setState({
			address
		});
		this.getUserDetails(address)
		this.getWalletDetails(address);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.address !== this.props.match.params.address) {
			this.setState({
				address: this.props.match.params.address,
			});
			this.getUserDetails(this.props.match.params.address)
			this.getWalletDetails(this.props.match.params.address);
		}
	}

	componentWillReceiveProps(props) {
		this.setState({
			address: props.match.params.address,
		});
		this.getUserDetails(props.match.params.address)
		this.getWalletDetails(props.match.params.address);
	}

	getUserDetails(address) {
		axiosPost('users/details', { address }).then((res) => {
			if (res && parseFloat(res.user_id) > 0) {
				let { user_firstname, user_lastname, user_phone_no } = res;

				this.setState({
					user_firstname,
					user_lastname,
					user_phone_no
				});
			}
		});
	}
	getWalletDetails(address) {
		const { offset, limit } = this.state
		axiosPost('transaction/address', { address, offset, limit }).then((res) => {
			if (res && typeof res.transactions !== 'undefined') {
				let { transactions } = res;

				let sent = _.filter(transactions, function (o) {
					return o.input.sender === address;
				});

				let received = _.filter(transactions, function (o) {
					return o.input.recipients.includes(address);
				});

				this.setState({
					transactions,
					sent,
					received
				});
			}
		});
	}

	exportDataToCSV() {
		const { transactions, address } = this.state;
		var exportData = [];
		if (transactions.length > 0) {
			transactions.map((t, index) => {
				let amount = parseFloat(t.input.amount) - parseFloat(t.outputMap[t.input.sender])
				let direction = t.input.sender.toLowerCase() === address.toLowerCase() ? "Sent" : "Received"
				let current_user_start_balance = t.receiver_balance.length > 0 ? parseFloat(t.receiver_balance[0]) - amount : 0
				let current_user_end_balance = t.receiver_balance.length > 0 ? t.receiver_balance[0] : 0
				exportData.push({
					"Transaction ID": t.id,
					"User Phone": t.sender_number,
					"User Start Balance": t.input.amount,
					"User End Balance": t.outputMap[t.input.sender],
					"Direction from Current User": direction,
					"Transaction Amount": amount,
					"Current User Start Balance": current_user_start_balance,
					"Current User End Balance": current_user_end_balance,
					"Date": moment(t.input.timestamp).format('DD-MM-YYYY hh:mm:ssa'),
					"Source": t.source,
					"Source ID": t.source_id,
					"Description": t.description
				})
				return true;
			})
			csvExporter.generateCsv(exportData);
		}
	}

	handleTableChange = (pagination, filters, sorter) => {
		const pager = { ...this.state.pagination };
		pager.current = pagination.current
		pager.pageSize = pagination.pageSize
		var offset = (pagination.pageSize * (pagination.current - 1))
		var limit = pagination.pageSize
		var { address } = this.state
		this.setState({
			pagination: pager,
			offset,
			limit
		}, () => {
			this.getWalletDetails(address)
		});

	};

	handleAmountChange = (e) => {
		let amount = e.target.value;
		this.setState({
			amount
		});
	};

	handleReceiverAddressChange = (e) => {
		let receiverAddress = e.target.value;
		this.setState({
			receiverAddress
		});
	};

	handleDescriptionChange = (e) => {
		let description = e.target.value;
		this.setState({
			description
		});
	};

	AddMoney() {
		const { amount, address, description, user_phone_no } = this.state;
		const { admin } = this.props;
		const sender = admin.admin_wallet_address;

		axiosPost('transaction/create-transaction', {
			recipient: address.toLowerCase(),
			amount: parseFloat(amount),
			sender: sender.toLowerCase(),
			source: 'lezzoo_pay_admin',
			source_id: admin.admin_id || 0,
			description: description || '',
			recipient_number: user_phone_no || ''
		}).then((res) => {
			let { message } = res;
			this.setState({
				amount: '',
				description: '',
				receiverAddress: ''
			});
			alert(message);
			this.getWalletDetails(address)
		});
	}

	SendMoney() {
		const { amount, address, receiverAddress, description, user_phone_no } = this.state;
		const { admin } = this.props;

		axiosPost('transaction/create-transaction', {
			recipient: receiverAddress.toLowerCase(),
			amount: parseFloat(amount),
			sender: address.toLowerCase(),
			source: 'lezzoo_pay_admin',
			source_id: admin.admin_id || 0,
			description: description || '',
			sender_number: user_phone_no || ''
		}).then((res) => {
			let { message } = res;
			this.setState({
				amount: '',
				description: '',
				receiverAddress: ''
			});
			alert(message);
			this.getWalletDetails(address)
		});
	}

	renderTransactions(transactions) {
		const columns = [
			{
				title: 'Index',
				dataIndex: 'id',
				key: 'id+record.input.timestamp',
				width: '7%',
				render: (text, record) => {
					return (
						<div>
							<p>{transactions.findIndex((t) => t.id === text) + 1}</p>
						</div>
					);
				}
			},
			{
				title: 'Transaction Id',
				dataIndex: 'id',
				key: 'id',
				width: '30%',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>
								<Link to={`/transaction-detail/${text}`}>{text.toUpperCase()}</Link>
							</p>
						</div>
					);
				}
			},
			{
				title: 'Sender Phone',
				dataIndex: 'sender_number',
				key: 'sender_number',
				width: '13%',
				render: (text, record) => {
					return (
						<div>
							<Link to={`/wallet-info/${record.input.sender}`}>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.user_firstname + " " + record.user_lastname}</p>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
							</Link>
						</div>
					);
				}
			},
			{
				title: 'Recipeint Phone',
				dataIndex: 'recipient_number',
				key: 'recipient_number',
				width: '13%',
				render: (text, record) => {
					return (
						record.input.recipients.includes(this.props.match.params.address) ?
							<div>
								<Link to={`/wallet-info/${this.props.match.params.address}`}>
									<p style={{ marginBottom: 0, paddingBottom: 0 }}>{this.state.user_firstname + " " + this.state.user_lastname}</p>
									<p style={{ marginBottom: 0, paddingBottom: 0 }}>{this.state.user_phone_no}</p>
								</Link>
							</div> :
							<div>
								<Link to={`/wallet-info/${record.input.recipient_numbers[text] ? record.input.recipient_numbers[text] : record.input.recipients[0]}`}>
									<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.receiver_user_firstname + " " + record.receiver_user_lastname}</p>
									<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
								</Link>
							</div>
					);
				}
			},
			{
				title: 'Source',
				dataIndex: 'source_id',
				key: 'source_id',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.source}</p>
						</div>
					);
				}
			},
			{
				title: 'Date',
				dataIndex: 'input',
				key: 'input.timestamp',
				width: '15%',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>
								{moment(text.timestamp).format('DD-MM-YYYY hh:mm:ss a')}
							</p>
						</div>
					);
				}
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				key: 'record.id+amount',
				render: (text, record) => {
					let Total = 0;
					for (let i = 0; i < record.input.recipients.length; i++) {
						Total += parseFloat(record.outputMap[record.input.recipients[i]]);
					}
					return (
						record.input.recipients.includes(this.props.match.params.address) ?
							<div style={{ width: '100%', textAlign: 'center' }}>
								<p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{FormatNumber(record.outputMap[this.props.match.params.address])}</p>
							</div>
							:
							<div style={{ width: '100%', textAlign: 'center' }}>
								<p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{FormatNumber(Total)}</p>
							</div>
					);
				}
			}
		];
		return (
			<Table
				scroll={{ y: 480 }}
				dataSource={transactions}
				pagination={{
					defaultPageSize: 10,
					showSizeChanger: true,
					position: 'bottom',
					pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
					total: transactions.length,
				}}
				rowKey={(record) => record.id}
				columns={columns}
				showHeader={true}
				bordered
			/>
		);
	}

	handleSearch = value => {
		let searchQuery = value
		if (value && value.length > 2) {
			axiosPost('users/search', { searchQuery })
				.then((res) => {
					let user_list = res
					this.setState({ user_list, value });
				})
		} else {
			this.setState({ user_list: [], value });
		}
	};

	render() {
		var { transactions, sent, received, address, user_phone_no, user_firstname, user_lastname, user_list } = this.state;
		var options = []
		for (var i = 0; i < user_list.length; i++) {
			options.push(<Option value={user_list[i].user_wallet_address} key={i}>
				{user_list[i].user_firstname + ' ' + user_list[i].user_lastname + ' - ' + user_list[i].user_phone_no}
			</Option>)
		}
		return (
			<div>
				<HeaderMenu selectedKey={'2'} />
				<Row style={{ padding: 20 }}>
					<Col span={4}>
						<span style={{ fontWeight: 'bold' }}>User : </span>
						{user_firstname ? user_firstname + ' ' : ''} {user_lastname ? user_lastname : ''}
					</Col>
					<Col span={4}>
						<span style={{ fontWeight: 'bold' }}>Phone no : </span>
						{user_phone_no ? user_phone_no : '-'}
					</Col>
					<Col span={12}>
						<span style={{ fontWeight: 'bold' }}>Wallet address : </span>
						{address ? address.toUpperCase() : ''}
					</Col>
					<Col span={3} style={{ textAlign: 'center' }}>
						<Button
							type="primary"
							onClick={() => {
								this.exportDataToCSV();
							}}
							style={{ width: '70%' }}
							size="small"
						>
							Export
				    	</Button>
					</Col>
				</Row>
				{
					address !== null ? <LezzooPayComponent walletAddress={address} /> : null
				}
				<div style={{ padding: '10px 20px', borderTop: '1px solid grey' }}>
					<Tabs defaultActiveKey="1">
						<TabPane tab={`All (${transactions.length})`} key="1">
							{transactions.length > 0 ? (
								this.renderTransactions(transactions)
							) : (
									<div style={{ textAlign: 'center' }}>
										<h1>No transactions Available</h1>
									</div>
								)}
						</TabPane>

						<TabPane tab={`Sent (${sent.length})`} key="2">
							{sent.length > 0 ? (
								this.renderTransactions(sent)
							) : (
									<div style={{ textAlign: 'center' }}>
										<h1>No transactions Available</h1>
									</div>
								)}
						</TabPane>

						<TabPane tab={`Received (${received.length})`} key="3">
							{received.length > 0 ? (
								this.renderTransactions(received)
							) : (
									<div style={{ textAlign: 'center' }}>
										<h1>No transactions Available</h1>
									</div>
								)}
						</TabPane>
						<TabPane tab="Recharge" key="4">
							<Form className="login-form" style={{ textAlign: 'center', padding: '5% 35%' }}>
								<Form.Item>
									<Input
										prefix={<Icon type="dollar" style={{ color: 'rgba(0,0,0,.25)' }} />}
										type="text"
										placeholder="Amount"
										value={this.state.amount}
										onChange={this.handleAmountChange}
									/>
								</Form.Item>
								<Form.Item>
									<Input
										type="text"
										placeholder="Description"
										value={this.state.description}
										onChange={this.handleDescriptionChange}
									/>
								</Form.Item>
								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="login-form-button"
										onClick={() => this.AddMoney(address)}
									>
										Recharge By Lezzoo
									</Button>
								</Form.Item>
							</Form>
						</TabPane>

						<TabPane tab="Send From This Account" key="5">
							<Form className="login-form" style={{ textAlign: 'center', padding: '5% 35%' }}>
								<Form.Item>
									<Select
										placeholder="Search user"
										showSearch
										allowClear
										style={{ alignSelf: 'center', fontSize: 13 }}
										optionFilterProp="children"
										onSearch={this.handleSearch}
										onChange={(value) => {
											this.setState({ receiverAddress: value });
										}}
									>
										{options}
									</Select>
								</Form.Item>

								<Form.Item>
									<Input
										prefix={<Icon type="dollar" style={{ color: 'rgba(0,0,0,.25)' }} />}
										type="text"
										placeholder="Amount"
										value={this.state.amount}
										onChange={this.handleAmountChange}
									/>
								</Form.Item>

								<Form.Item>
									<Input
										type="text"
										placeholder="Description"
										value={this.state.description}
										onChange={this.handleDescriptionChange}
									/>
								</Form.Item>
								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="login-form-button"
										onClick={() => this.SendMoney(address)}
									>
										Send Money
									</Button>
								</Form.Item>
							</Form>
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const states = {
		errorMessage: state.errorMessage,
		admin: state.admin
	};
	return states;
};

export default connect(mapStateToProps, {})(WalletInfo);
