import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { logoutAdmin } from '../actions/adminAction'

class Logout extends PureComponent {
  componentDidMount() {
    this.props.logoutAdmin()
  }

  render() {
    return (
      <Redirect to="/" />
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  errorMessage: state.errorMessage,
  admin: state.admin
});

export default connect(mapStateToProps, {
  logoutAdmin
})(Logout);