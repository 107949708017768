import React, { PureComponent } from 'react'
import moment from 'moment'
import { axiosGet } from '../actions/Axios'
import HeaderMenu from './HeaderMenu'
class BlockDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hash: this.props.hash,
      block: null
    }
  }

  componentDidMount() {
    const { hash } = this.props.match.params
    this.setState({
      hash
    })
    this.getBlockDetails(hash)
  }

  componentWillReceiveProps(props) {
    let hash = props.match.params.hash
		this.setState({
			hash,
		});
		this.getBlockDetails(hash)
  }
  
  getBlockDetails(hash) {
    axiosGet('blockchain/hash/' + hash).then((res) => {
      let block = res
      this.setState({
        block
      })
    });
  }

  render() {
    const { block } = this.state;
    return (
      <div>
        <HeaderMenu selectedKey={'1'} />
        {
          block ? <div style={{ marginLeft: 20 }}><h1>Block Details</h1>
            <div key={block.index} style={{ border: '1px solid grey', padding: 10 }}>
              <p>Index : {block.index}</p>
              <p>Date-Time : {moment(parseInt(block.timestamp)).format('LLLL')}</p>
              <p>Previos Hash : {block.prevBlockHash}</p>
              <p>Hash : {block.hash}</p>
              <p>Nonce : {block.nonce}</p>
              <p>Difficulty : {block.difficulty}</p>
              {
                block.transactions.map(transaction =>
                  <div key={transaction.id}>
                    <p>Transaction Id : {transaction.id}</p>
                    <p>Sender : {transaction.input.sender}</p>
                    <p>Amount : {transaction.input.amount}</p>
                    <div>
                      Recipients : {
                        transaction.input.recipients.map(recipient =>
                          <p key={recipient}>{recipient}</p>
                        )
                      }
                    </div>
                    <div>
                      OutputMap :
                     <div>
                        {
                          transaction.input.recipients.map(recipient =>
                            <p key={recipient}>{recipient} = {transaction.outputMap[recipient]} </p>
                          )
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div></div> : null
        }
      </div>
    )
  }
}

export default BlockDetail;