import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { axiosPost } from '../actions/Axios';
import _ from 'lodash';
import { ExportToCsv } from 'export-to-csv';
import { Row, Col, DatePicker, Select, Input, Button, Table } from 'antd';
import moment from 'moment';
import HeaderMenu from './HeaderMenu';
import { StringUcFirst, FormatNumber } from '../helper/Formats';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const REACT_APP_LEZZOO_PAY_ADMIN_WALLET_ADDRESS = process.env.REACT_APP_REACT_APP_LEZZOO_PAY_ADMIN_WALLET_ADDRESS;

const options = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	useTextFile: false,
	useBom: false,
	useKeysAsHeaders: true
};

const csvExporter = new ExportToCsv(options);

class Home extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			// selected_date: [moment().subtract(29, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
			selected_date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
			pagination: { current: 0, pageSize: 0 },
			transactions: [],
			showTransactions: [],
			user_name_phone_list: [],
			totalCount: 0,
			user_phone_name: 0,
			source: 0,
			sender_phone: 0,
			receiver_phone: 0,
			amount: null,
			transaction_id: null,
			offset: 0,
			limit: 10
		};
	}

	componentDidMount() {
		this.getTransactionList();
	}

	getTransactionList() {
		if (this.props.admin && this.props.admin.admin_id) {
			const { offset, limit, selected_date } = this.state
			const start = selected_date[0] ? selected_date[0].toString() : moment().format('YYYY-MM-DD');
			const end = selected_date[1] ? selected_date[1].toString() : moment().format('YYYY-MM-DD');
			axiosPost('transaction/filter', { start, end, offset, limit })
				.then((res) => {
					if (res) {
						let { transactions, totalCount } = res
						this.setState({
							transactions,
							showTransactions: transactions,
							totalCount
						})
						this.createUserPhoneList(transactions);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			setTimeout(() => {
				this.getTransactionList();
			}, 1000);
		}
	}

	// handleTableChange = (pagination, filters, sorter) => {
	// 	const pager = { ...this.state.pagination };
	// 	pager.current = pagination.current
	// 	pager.pageSize = pagination.pageSize
	// 	var offset = (pagination.pageSize * (pagination.current - 1))
	// 	var limit = pagination.pageSize
	// 	this.setState({
	// 		pagination: pager,
	// 		offset,
	// 		limit
	// 	}, () => {
	// 		this.getTransactionList()
	// 	});

	// };

	exportDataToCSV() {
		var showTransactions = [];
		var exportData = [];
		const start = this.state.selected_date[0] ? this.state.selected_date[0].toString() : null;
		const end = this.state.selected_date[1] ? this.state.selected_date[1].toString() : null;
		const offset = 0, limit = 100000

		axiosPost('transaction/filter', { start, end, offset, limit })
			.then((res) => {
				let { transactions } = res
				showTransactions = transactions
				if (showTransactions.length > 0) {
					showTransactions.map((t, index) => {
						let amount = parseFloat(t.input.amount) - parseFloat(t.outputMap[t.input.sender])
						//let direction = t.input.sender.toLowerCase() === address.toLowerCase() ? "Sent" : "Received"
						let current_user_start_balance = t.receiver_balance.length > 0 ? parseFloat(t.receiver_balance[0]) - parseFloat(amount) : 0
						let current_user_end_balance = t.receiver_balance.length > 0 ? t.receiver_balance[0] : 0
						exportData.push({
							// Index: index + 1,
							"Transaction ID": t.id,
							"User": t.user_firstname + ' ' + t.user_lastname,
							"User Phone": t.sender_number,
							"Recipent Name": t.receiver_user_firstname + " " + t.receiver_user_lastname,
							"Recipient Phone": t.recipient_number,
							// 'User Type': t.user_type_name,
							// 'Account Type': t.account_type,
							"User Start Balance": t.input.amount,
							"User End Balance": t.outputMap[t.input.sender],
							"Direction from Current User": "Sent",
							// 'Sender Wallet': t.input.sender,
							// 'Recipient Wallet': t.input.recipients[0],
							"Transaction Amount": amount,
							"Current User Start Balance": current_user_start_balance,
							"Current User End Balance": current_user_end_balance,
							"Date": moment(t.input.timestamp).format('DD-MM-YYYY'),
							"Time": moment(t.input.timestamp).format('HH:mm:ss'),
							"Source Id": t.source_id,
							"Source": t.source,
							"Description": t.description,
							// Agent: t.is_agent
						});
						return true;
					});
					csvExporter.generateCsv(exportData);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	createUserPhoneList(showTransactions) {
		const user_phone_list = [];
		const user_name_phone_list = [];
		if (showTransactions !== null) {
			showTransactions.map((transaction) => {
				if (!_.includes(user_phone_list, transaction.sender_number)) {
					user_phone_list.push(transaction.sender_number);
					user_name_phone_list.push({
						user_firstname: transaction.user_firstname,
						user_lastname: transaction.user_lastname,
						user_phone: transaction.sender_number
					});
				}
				return true;
			});
		}
		this.setState({
			user_name_phone_list
		});
	}

	handleFilterChange() {
		const { user_phone_name, source, sender_phone, receiver_phone, amount, transaction_id } = this.state;
		const source_list = ['order_made', 'order_refund', 'user', 'order_compensation', 'others'];
		var showTransactions = this.state.transactions;
		let length = 0;
		if (user_phone_name) {
			showTransactions = _.filter(showTransactions, function (o) {
				return o.sender_number === user_phone_name;
			});
		}
		if (source) {
			if (source === 'others') {
				showTransactions = _.filter(showTransactions, function (o) {
					return !source_list.includes(o.source);
				});
			} else {
				showTransactions = _.filter(showTransactions, function (o) {
					return o.source === source;
				});
			}
		}
		if (sender_phone) {
			showTransactions = _.filter(showTransactions, function (o) {
				return o.sender_number.includes(sender_phone);
			});
		}
		if (receiver_phone) {
			showTransactions = _.filter(showTransactions, function (o) {
				return o.recipient_number.includes(receiver_phone);
			});
		}
		if (amount && parseInt(amount) > 0) {
			showTransactions = _.filter(showTransactions, function (o) {
				return parseFloat(o.amount) === parseFloat(amount);
			});
		}
		if (transaction_id) {
			showTransactions = _.filter(showTransactions, function (o) {
				return o.id.toLowerCase().includes(transaction_id.toLowerCase());
			});
		}
		length = showTransactions.length
		this.setState({
			showTransactions,
			length
		});
	}
	handleAmountChange = (value) => {
		let amount = value;
		this.setState({
			amount
		});
	};

	handleTransactionIdChange = (value) => {
		let transaction_id = value;
		this.setState({
			transaction_id
		});
	};

	handleDateChange(dateStrings) {
		const selected_date =
			dateStrings.length > 0 ? dateStrings : [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
		this.setState(
			{
				selected_date
			},
			() => {
				this.getTransactionList();
			}
		);
	}
	renderSenderPhoneSelect() {
		return (
			<Search
				placeholder="All Senders"
				size="small"
				style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
				onChange={(e) => this.setState({ sender_phone: e.target.value })}
				onSearch={() => this.handleFilterChange()}
				onPressEnter={() => this.handleFilterChange()}
			/>
		);
	}
	renderRecipientPhoneSelect() {
		return (
			<Search
				placeholder="All Senders"
				size="small"
				style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
				onChange={(e) => this.setState({ receiver_phone: e.target.value })}
				onSearch={() => this.handleFilterChange()}
				onPressEnter={() => this.handleFilterChange()}
			/>
		);
	}
	renderDateFilter() {
		return (
			<RangePicker
				style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
				format="YYYY-MM-DD"
				disabledDate={this.disabledDate}
				defaultValue={[moment(), moment()]}
				ranges={{
					Today: [moment(), moment()],
					Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
					'Last 7 Days': [moment().subtract(6, 'days'), moment()],
					'Last 30 Days': [moment().subtract(29, 'days'), moment()],
					'This Month': [moment().startOf('month'), moment()],
					'Last Month': [
						moment().subtract(1, 'month').startOf('month'),
						moment().subtract(1, 'month').endOf('month')
					]
				}}
				size="small"
				onChange={(date, dateStrings) => this.handleDateChange(dateStrings)}
			/>
		);
	}
	renderUserAndPhoneSelect() {
		const { user_name_phone_list } = this.state;
		return (
			<Select
				value={this.state.user_phone_name}
				showSearch
				allowClear
				style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
				placeholder="User/Phone No"
				optionFilterProp="children"
				size="small"
				onChange={(value) => {
					this.setState(
						{
							user_phone_name: value || 0
						},
						() => {
							this.handleFilterChange();
						}
					);
				}}
				filterOption={(input, option) => {
					return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
				}}
			>
				<Option style={{ fontSize: 13 }} key={0} value={0}>
					ALL Users
				</Option>
				{user_name_phone_list.length > 0 ? (
					user_name_phone_list.map((user) => (
						<Option style={{ fontSize: 13 }} key={0} value={user.user_phone}>
							{user.user_firstname + ' ' + user.user_lastname + ' - ' + user.user_phone}
						</Option>
					))
				) : null}
			</Select>
		);
	}
	renderSourceFilterOptions() {
		return (
			<Select
				value={this.state.source}
				showSearch
				allowClear
				style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
				placeholder="Source"
				optionFilterProp="children"
				size="small"
				onChange={(value) => {
					this.setState(
						{
							source: value || 0
						},
						() => {
							this.handleFilterChange();
						}
					);
				}}
				filterOption={(input, option) => {
					return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
				}}
			>
				<Option style={{ fontSize: 13 }} key={0} value={0}>
					ALL Sources
				</Option>
				<Option style={{ fontSize: 13 }} key={1} value="order_made">
					Order made
				</Option>
				<Option style={{ fontSize: 13 }} key={0} value="order_refund">
					Order Refund
				</Option>
				<Option style={{ fontSize: 13 }} key={0} value="user">
					User
				</Option>
				<Option style={{ fontSize: 13 }} key={0} value="order_compensation">
					Order Compensation
				</Option>
				<Option style={{ fontSize: 13 }} key={0} value="others">
					Others
				</Option>
			</Select>
		);
	}
	renderTransactionsInfo() {
		const { totalCount, showTransactions } = this.state;
		var sentAmount = 0,
			receivedAmount = 0;
		showTransactions.map((transaction) => {
			if (
				transaction &&
				transaction.input &&
				transaction.input.sender === REACT_APP_LEZZOO_PAY_ADMIN_WALLET_ADDRESS
			)
				sentAmount += parseFloat(transaction.outputMap[REACT_APP_LEZZOO_PAY_ADMIN_WALLET_ADDRESS]);
			if (
				transaction &&
				transaction.input &&
				transaction.input.recipients.includes(REACT_APP_LEZZOO_PAY_ADMIN_WALLET_ADDRESS)
			)
				receivedAmount += parseFloat(transaction.outputMap[REACT_APP_LEZZOO_PAY_ADMIN_WALLET_ADDRESS]);
			return true;
		});
		return (
			<Row style={{ paddingLeft: 10 }}>
				<Col span={5}>
					<span style={{ fontWeight: 'bold' }}>
						No. of Transactions : {totalCount ? FormatNumber(totalCount) : 0}
					</span>
				</Col>
				<Col span={5}>
					<span style={{ fontWeight: 'bold' }}>Sent Amount : {FormatNumber(sentAmount)}</span>
				</Col>
				<Col span={5}>
					<span style={{ fontWeight: 'bold' }}>Received Amount : {FormatNumber(receivedAmount)}</span>
				</Col>
				<Col span={5}>
					<span style={{ fontWeight: 'bold' }}>Balance : {FormatNumber(receivedAmount - sentAmount)}</span>
				</Col>
				<Col span={3} push={1}>
					<Button
						type="primary"
						onClick={() => {
							this.exportDataToCSV();
						}}
					>
						Export
					</Button>
				</Col>
			</Row>
		);
	}
	renderTransactionList() {
		const { showTransactions, pagination } = this.state;
		const { current, pageSize } = pagination

		const columns = [
			{
				title: 'Index',
				dataIndex: 'id',
				key: 'id',
				width: '6%',
				render: (text, record) => {
					return (
						<div>
							{/* <p>{(showTransactions.findIndex((t) => t.id === text) + 1) + ((current-1) * pageSize)}</p> */}
							<p>{showTransactions.findIndex((t) => t.id === text) + 1}</p>
						</div>
					);
				}
			},
			{
				title: 'User/Phone',
				dataIndex: 'user_firstname',
				key: 'user_firstname+id',
				width: '13%',
				render: (text, record) => {
					return (
						<div>
							<Link to={`/transaction-detail/${record.id}`}>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text + ' ' + record.user_lastname}</p>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.sender_number}</p>
							</Link>
						</div>
					);
				}
			},
			{
				title: 'User Balance',
				dataIndex: 'balance',
				key: 'balance+id',
				width: '12%',
				render: (text, record) => {
					return <p style={{ marginBottom: 0, paddingBottom: 0 }}>{FormatNumber(text)}</p>;
				}
			},
			{
				title: 'User/Wallet Type',
				dataIndex: 'user_type_name',
				key: 'user_type_name+id',
				width: '11%',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(record.account_type)}</p>
						</div>
					);
				}
			},
			{
				title: 'Source',
				dataIndex: 'source_id',
				key: 'source_id+id',
				width: '12%',
				align: 'center',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.source}</p>
						</div>
					);
				}
			},
			{
				title: 'Sender',
				dataIndex: 'sender',
				key: 'sender+id',
				width: '11%',
				render: (text, record) => {
					return (
						<div>
							<Link to={`/wallet-info/${record.input.sender}`}>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.sender_number}</p>
							</Link>
						</div>
					);
				}
			},
			{
				title: 'Receiver',
				dataIndex: 'recipient_number',
				key: 'recipient_number+id',
				width: '11%',
				render: (text, record) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<Link to={`/wallet-info/${record.input.recipient_numbers[text]}`}>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.receiver_user_firstname + " " + record.receiver_user_lastname}</p>
								<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
							</Link>
						</div>
					);
				}
			},
			{
				title: 'Date',
				dataIndex: 'input',
				key: 'input.timestamp',
				width: '9%',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>
								{moment(text.timestamp).format('DD-MM-YYYY')}
							</p>
							<p>{moment(text.timestamp).format('hh:mm:ss a')}</p>
						</div>
					);
				}
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				key: 'amount+id',
				width: '9%',
				// render: (text, record) => {
				// 	return (
				// 		<div>
				// 			<p style={{ marginBottom: 0, paddingBottom: 0 }}>{FormatNumber(text)}</p>
				// 		</div>
				// 	);
				// }
				render: (text, record) => {
					let Total = 0;
					//text.map(t => Total += parseFloat(t.amount))
					for (let i = 0; i < record.input.recipients.length; i++) {
						Total += parseFloat(record.outputMap[record.input.recipients[i]]);
					}
					return (
						<div style={{ width: '100%', textAlign: 'center' }}>
							<p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{FormatNumber(Total)}</p>
						</div>
					);
				}
			},
			{
				title: 'Agent',
				dataIndex: 'is_agent',
				key: 'is_agent+id',
				render: (text, record) => {
					return (
						<div>
							<p style={{ marginBottom: 0, paddingBottom: 0 }}>{text ? text : 0}</p>
						</div>
					);
				}
			}
		];
		return (
			<Table
				scroll={{ y: 480 }}
				pagination={{
					defaultPageSize: 10,
					showSizeChanger: true,
					position: 'bottom',
					pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
					total: this.state.showTransactions.length
				}}
				onChange={this.handleTableChange}
				dataSource={showTransactions}
				rowKey={(record) => record.id}
				columns={columns}
				showHeader={true}
				bordered
			/>
		);
	}
	render() {

		return (
			<div>
				<HeaderMenu selectedKey={'7'} />
				<br />
				<Row style={{ textAlign: 'center' }}>
					<Col span={4}>{this.renderDateFilter()}</Col>
					<Col span={4}>{this.renderUserAndPhoneSelect()}</Col>
					<Col span={3}>{this.renderSourceFilterOptions()}</Col>
					<Col span={3}>{this.renderSenderPhoneSelect()}</Col>
					<Col span={3}>{this.renderRecipientPhoneSelect()}</Col>
					<Col span={3}>
						<Search
							placeholder="Enter Amount"
							size="small"
							style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
							onChange={(e) => this.handleAmountChange(e.target.value)}
							onSearch={() => this.handleFilterChange()}
							onPressEnter={() => this.handleFilterChange()}
						/>
					</Col>
					<Col span={4}>
						<Search
							placeholder="Enter Trans_Id/substring"
							size="small"
							style={{ width: '95%', alignSelf: 'center', fontSize: 13 }}
							onChange={(e) => this.handleTransactionIdChange(e.target.value)}
							onSearch={() => this.handleFilterChange()}
							onPressEnter={() => this.handleFilterChange()}
						/>
					</Col>
				</Row>
				<hr />
				{this.renderTransactionsInfo()}
				<hr />
				{this.state.showTransactions.length > 0 ? this.renderTransactionList() : null}
			</div>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	const states = {
		errorMessage: state.errorMessage,
		admin: state.admin
	};
	return states;
};

export default connect(mapStateToProps, {})(Home);
