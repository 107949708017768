import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { axiosGet, axiosPost } from '../actions/Axios'
import { Row, Col, Button, Input, Table, DatePicker } from 'antd'
import HeaderMenu from './HeaderMenu'
import { StringUcFirst, FormatNumber } from '../helper/Formats'
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';

function checkSearch(data, searchQuery) {
  if (searchQuery && searchQuery.length > 2) {
    const stringifyOrder = JSON.stringify(data);
    const stringifyOrderLower = stringifyOrder.toLowerCase();
    const findQuery = stringifyOrderLower.indexOf(searchQuery.toLowerCase());

    return findQuery > -1;
  } else {
    return true;
  }
}
const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: false,
  useKeysAsHeaders: true
};

const csvExporter = new ExportToCsv(options);

class AdminList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      admins: [],
      showAdmins: [],
      searchQuery: null,
      pagination: { current: 0, pageSize: 0 },
      offset: 0,
      limit: 10,
      totalAdmins: 0,
      date: moment().format('YYYY-MM-DD')
    }
  }

  componentDidMount() {
    this.getAdminList()
  }
  getAdminList() {
    const { offset, limit, date, searchQuery } = this.state;
    axiosPost('users/admins', { offset, limit, date, searchQuery }).then((res) => {
      var admins = res.admins
      var showAdmins = res.admins
      var totalAdmins = res.totalAdmins.count
      this.setState({
        showAdmins,
        admins,
        totalAdmins
      })
    })
  }

  onSearchData(searchQuery) {
    const { admins } = this.state
    var showAdmins = admins
    this.setState({
      offset: 0,
      showAdmins,
      searchQuery
    }, () => {
      this.getAdminList()
    })
  }

  exportDataToCSV() {
    const { admins } = this.state;
    var exportData = [];
    if (admins.length > 0) {
      admins.map((u, index) => {
        exportData.push({
          Index: index + 1,
          Name: u.user_firstname + ' ' + u.user_lastname,
          "User Phone": u.user_phone_no,
          "User End Balance": u.balance,
          "Type": u.user_type_name,
          "Date": moment().format('YYYY-MM-DD')
        });
        return true;
      })
      csvExporter.generateCsv(exportData);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    var offset = (pagination.pageSize * (pagination.current - 1))
    var limit = pagination.pageSize
    this.setState({
      pagination: pager,
      offset,
      limit
    }, () => {
      this.getAdminList()
    });
  };

  stringUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderAdminsList() {
    const { showAdmins, pagination } = this.state;
    const { current, pageSize } = pagination
    const columns = [
      {
        title: 'Index',
        dataIndex: 'admin_wallet_address',
        key: 'record.admin_id',
        width: '5%',
        render: (text, record) => {
          return (
            <div>
              <p>{(showAdmins.findIndex((u) => u.admin_wallet_address === text) + 1) + ((current - 1) * pageSize)}</p>
            </div>
          );
        }
      },
      {
        title: 'Admin',
        dataIndex: 'admin_firstname',
        key: 'admin_firstname+record.admin_id',
        width: '10%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Link to={`/wallet-info/${record.admin_wallet_address}`}>
                  {text + " " + record.admin_lastname}
                </Link>
              </p>
            </div>
          );
        }
      },
      {
        title: 'Admin Phone',
        dataIndex: 'admin_phone_no',
        key: 'admin_phone_no',
        width: '12%',
        render: (text, record) => {
          return (
            <p style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Link to={`/wallet-info/${record.admin_wallet_address}`}>
                {text}
              </Link>
            </p>
          );
        }
      },
      {
        title: 'Wallet',
        dataIndex: 'admin_wallet_address',
        key: 'admin_wallet_address',
        width: '41%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Link to={`/wallet-info/${text}`}>
                  {text}
                </Link>
              </p>
            </div>
          );
        }
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance+admin_id',
        width: '12%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{FormatNumber(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Admin Type',
        dataIndex: 'user_type_name',
        key: 'user_type_name',
        width: '8%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Wallet Type',
        dataIndex: 'account_type',
        key: 'account_type',
        width: '10%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst("Business")}</p>
            </div>
          );
        }
      },
      // {
      //   title: 'Agent',
      //   dataIndex: 'is_agent',
      //   key: 'is_agent',
      //   width: '7%',
      //   render: (text, record) => {
      //     return (
      //       parseInt(text) === 0 ?
      //         <Button
      //           type="primary"
      //           size="small"
      //           onClick={() => this.permitRevokeAgent(record.user_phone_no, 1)}
      //         >
      //           Permit
      //           </Button>
      //         :
      //         <Button
      //           type="danger"
      //           size="small"
      //           onClick={() => this.permitRevokeAgent(record.user_phone_no, 0)}
      //         >
      //           Revoke
      //       </Button>
      //     );
      //   }
      // }
    ]

    return (
      <Table
        scroll={{ y: 480 }}
        dataSource={showAdmins}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          position: 'bottom',
          pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
          total: this.state.totalUsers,
        }}
        rowKey={(record) => record.admin_wallet_address}
        columns={columns}
        showHeader={true}
        onChange={this.handleTableChange}
        bordered
      />
    )
  }
  onChange = (d, dateString) => {
    let date = dateString ? dateString : moment().format('YYYY-MM-DD')
    this.setState({
      date
    }, () => {
      this.getAdminList()
    })
  }
  render() {
    return (
      <div>
        <HeaderMenu selectedKey={'8'} />
        <div style={{ margin: 30 }}>
          <Row gutter={16}>
            <Col span={9}>
              <h1>Admins List : {this.state.totalAdmins ? this.state.totalAdmins : null}</h1>
            </Col>
            <Col span={4}>
              <DatePicker
                onChange={this.onChange}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={8}>
              <Input.Search
                placeholder="Search Admin, Admin Phone, Wallet, Admin Type"
                onSearch={(value) => this.onSearchData(value)}
                // style={{ width: '95%' }}
                enterButton="Search"
                size="default"
                allowClear
              />
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                onClick={() => {
                  this.exportDataToCSV();
                }}
              >
                Export
				    	</Button>
            </Col>
          </Row>
          {
            this.renderAdminsList()
          }
        </div>
      </div >
    )
  }
}

export default AdminList;