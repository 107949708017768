import { actionTypes } from '../actions';

export const admin = (state = {}, action) => {
	const { type, admin, error } = action;

	switch (type) {
		case actionTypes.ADMIN_DATA_SUCCESS:
			return admin;
		case actionTypes.ADMIN_DATA_ERROR:
			return { loading: true };
		case actionTypes.ADMIN_DATA_LOADING:
			return { error: error };
		case actionTypes.ADMIN_LOGOUT_SUCCESS:
			return admin
		default:
			return state;
	}
};

