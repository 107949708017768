import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Input, Icon, Spin, Table, Row, Col, DatePicker } from 'antd'
import { axiosGet, axiosPost } from '../actions/Axios'
import HeaderMenu from './HeaderMenu'
import { StringUcFirst, FormatNumber } from '../helper/Formats'
import moment from 'moment'

class WalletList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      walletList: [],
      recipient: '',
      visible: false,
      amount: '',
      recipient_phone_no: '',
      spinning: true,
      offset: 0,
      limit: 10,
      totalWallets: 0,
      pagination: { current: 0, pageSize: 0 },
      date: moment().format('YYYY-MM-DD')
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  getWalletList() {
    const { offset, limit, date } = this.state;
    axiosPost('wallet/all', { offset, limit, date }).then((res) => {
      let walletList = [];
      let { data, totalWallets } = res
      Object.values(data)
        .map((wallet) => walletList.push(wallet))
      if (this.state.walletList.length !== res.length) {
        this.setState({
          walletList,
          totalWallets: totalWallets.count,
          spinning: false
        })
      }
    })
  }
  fetchData() {
    this.getWalletList()
    // setTimeout(() => {
    //   this.fetchData();
    // }, 3000);
  }

  handle = e => {
    this.setState({
      recipient: '',
      recipient_phone_no: '',
      visible: false,
    })
  }

  handleAmountChange = (e) => {
    let amount = e.target.value;
    this.setState({
      amount
    })
  }

  createTransaction() {
    const { amount, recipient, recipient_phone_no } = this.state;
    const admin = JSON.parse(localStorage.getItem('admin'))
    const sender = admin.admin_wallet_address
    const sender_number = admin.admin_phone_no

    axiosPost('transaction/create-transaction', {
      recipient: recipient.toLowerCase(),
      amount: parseFloat(amount),
      sender: sender.toLowerCase(),
      sender_number,
      recipient_number: recipient_phone_no,
      source: 'test_admin',
      source_id: 1,
      description: 'Testing'
    })
      .then((res) => {
        let { message } = res
        this.setState({
          recipient: '',
          recipient_phone_no: '',
          amount: '',
          visible: false
        })
        alert(message)
      })
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current
    pager.pageSize = pagination.pageSize
    var offset = (pagination.pageSize * (pagination.current - 1))
    var limit = pagination.pageSize
    this.setState({
      pagination: pager,
      offset,
      limit
    }, () => {
      this.getWalletList()
    });

  };
  renderwalletList() {
    const { walletList, pagination } = this.state;
    const { current, pageSize } = pagination
    const columns = [
      {
        title: 'Index',
        dataIndex: 'address',
        key: 'address+balance',
        width: '5%',
        render: (text, record) => {
          return (
            <div>
              <p>{(walletList.findIndex((w) => w.address === text) + 1) + ((current - 1) * pageSize)}</p>
            </div>
          );
        }
      },
      {
        title: 'Wallet Address',
        dataIndex: 'address',
        key: 'address',
        width: '44%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Link to={`/wallet-info/${text}`}>
                  {text.toUpperCase()}
                </Link>
              </p>
            </div>
          );
        }
      },
      {
        title: 'Phone No',
        dataIndex: 'user_phone_no',
        key: 'user_phone_no',
        width: '12%',
        render: (text, record) => {
          return (
            <div style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Link to={`/wallet-info/${record.address}`}>
                <p style={{ marginBottom: 0, paddingBottom: 0 }}>{record.user_firstname + " " + record.user_lastname}</p>
                <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
              </Link>
            </div>
          );
        }
      },
      {
        title: 'User Type',
        dataIndex: 'user_type_name',
        key: 'user_type_name',
        width: '9%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Wallet Type',
        dataIndex: 'account_type',
        key: 'account_type',
        width: '9%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{StringUcFirst(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        width: '11%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{FormatNumber(text)}</p>
            </div>
          );
        }
      },
      {
        title: 'Action',
        dataIndex: 'address',
        key: 'address+user_phone_no',
        render: (text, record) => {
          return (
            <Button
              type="primary"
              onClick={() => this.setState({
                recipient_phone_no: record.user_phone_no,
                recipient: text,
                visible: true
              })}
            >
              Send Money
            </Button>
          );
        }
      }
    ]

    return (
      <Table
        scroll={{ y: 550 }}
        dataSource={walletList}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          position: 'bottom',
          pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
          total: this.state.totalWallets,
        }}
        rowKey={(record) => record.address}
        columns={columns}
        showHeader={true}
        onChange={this.handleTableChange}
        bordered
      />
    )
  }
  onChange = (d, dateString) => {
    let date = dateString ? dateString : moment().format('YYYY-MM-DD')
    this.setState({
      date
    }, () => {
      this.getWalletList()
    })
  }
  render() {
    return (
      <div>
        <HeaderMenu selectedKey={'2'} />
        <div style={{ margin: 10 }}>
          <Row>
            <Col span={19}>
              <h1>Wallet List : {this.state.totalWallets}</h1>
            </Col>
            <Col span={4} style={{ marginTop: 10 }}>
              <DatePicker
                onChange={this.onChange}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>

          <Spin size="large" spinning={this.state.spinning} style={{ marginTop: '20%' }}>
            {
              this.renderwalletList()
            }
          </Spin>
          <Modal
            title={<div style={{ textAlign: 'center' }}>Send Money</div>}
            visible={this.state.visible}
            onOk={this.handle}
            onCancel={this.handle}
            footer={null}
          >
            <Form className="login-form" style={{ textAlign: 'center' }}>
              <Form.Item>
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="text"
                  value={this.state.recipient_phone_no}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  prefix={<Icon type="dollar" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="text"
                  placeholder="Amount"
                  value={this.state.amount}
                  onChange={this.handleAmountChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => this.createTransaction()}
                >
                  Send Money
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

export default WalletList;