import React, { PureComponent } from 'react'
import { Form, Icon, Input, Button, Select } from 'antd'
import { axiosPost } from '../actions/Axios'
import HeaderMenu from './HeaderMenu'
const { Option } = Select;

class CreateTransaction extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      recipient: null,
      amount: null,
      address: null,
      data: [],
      user_list: [],
      value: ''
    }
  }

  createTransaction = () => {
    const { recipient, amount } = this.state;
    const admin = JSON.parse(localStorage.getItem('admin'))
    const sender = admin.admin_wallet_address
    const sender_number = admin.admin_phone_no

    axiosPost('transaction/create-transaction', {
      recipient: recipient.toLowerCase(),
      amount: parseFloat(amount),
      sender: sender.toLowerCase(),
      sender_number,
      source: 'from_lezzoo_admin',
      source_id: 1,
      description: 'Testing'
    })
      .then((res) => {
        this.setState({
          recipient: null,
          amount: null
        })
        let { message } = res
        alert(message)
      })
  }

  handleAmountChange = (e) => {
    let amount = e.target.value;
    this.setState({
      amount
    });
  };

  handleSearch = value => {
    let searchQuery = value
    if (value && value.length > 2) {
      axiosPost('users/search', { searchQuery })
        .then((res) => {
          let user_list = res
          this.setState({ user_list, value });
        })
    } else {
      this.setState({ user_list: [], value });
    }
  };

  render() {
    const { user_list } = this.state
    var options = []
    for (var i = 0; i < user_list.length; i++) {
      options.push(<Option value={user_list[i].user_wallet_address} key={i}>
        {user_list[i].user_firstname + ' ' + user_list[i].user_lastname + ' - ' + user_list[i].user_phone_no}
      </Option>)
    }
    return (
      <div>
        <HeaderMenu selectedKey={'4'} />
        <Form className="login-form" style={{ textAlign: 'center', padding: '5% 35%' }}>
          <Form.Item>
            <Select
              placeholder="Search user"
              // value={this.state.recipient}
              showSearch
              allowClear
              style={{ alignSelf: 'center', fontSize: 13 }}
              optionFilterProp="children"
              onSearch={this.handleSearch}
              onChange={(value) => {
                this.setState({ recipient: value });
              }}
            >
              {/* <Option value="0">
                Search User
              </Option> */}
              {options}
            </Select>
          </Form.Item>
          {/* <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Recipient Address"
              onChange={this.handleRecipientChange}
            />
          </Form.Item> */}
          <Form.Item>
            <Input
              prefix={<Icon type="dollar" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Amount"
              onChange={this.handleAmountChange}
            />
          </Form.Item>
          {/* <Form.Item>
            <Input
              prefix={<Icon type="wallet" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Address"
              onChange={this.handleSenderChange}
            />
          </Form.Item> */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={this.createTransaction}
            >
              Create
          </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default CreateTransaction