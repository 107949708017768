import React, { PureComponent } from 'react'
import 'antd/dist/antd.css'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd';
const { Header } = Layout;
class HeaderMenu extends PureComponent {
  handleClick = e => {

    this.setState({
      current: e.key,
    });
  };
  render() {
    return (
      <Layout className="layout">
        <Header>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[this.props.selectedKey]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="7"><Link to="/home" style={{ marginRight: 20 }}>Home</Link></Menu.Item>
            <Menu.Item key="9"><Link to="/dashboard" style={{ marginRight: 20 }}>Dashboard</Link></Menu.Item>
            <Menu.Item key="1"><Link to="/blocks" style={{ marginRight: 20 }}>Blocks</Link></Menu.Item>
            <Menu.Item key="8"><Link to="/admins" style={{ marginRight: 20 }}>Admin List</Link></Menu.Item>
            <Menu.Item key="6"><Link to="/users" style={{ marginRight: 20 }}>User List</Link></Menu.Item>
            <Menu.Item key="2"><Link to="/walletList" style={{ marginRight: 20 }}>Wallet List</Link></Menu.Item>
            <Menu.Item key="3"><Link to="/transactions" style={{ marginRight: 20 }}>Transactions</Link></Menu.Item>
            <Menu.Item key="4"><Link to="/create-transaction">Create Transaction</Link></Menu.Item>
            <Menu.Item key="5"><Link to="/Logout">Logout</Link></Menu.Item>
          </Menu>
        </Header>
      </Layout>
    )
  }
}

export default HeaderMenu