import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { axiosGet, axiosPost } from '../actions/Axios'
import { Table } from 'antd'
import moment from 'moment'
import HeaderMenu from './HeaderMenu'
import { StringUcFirst, FormatNumber } from '../helper/Formats'

class BlockchainList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      chainList: [],
      length: 0,
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true,
        position: 'bottom',
        pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000']
      },
      offset: 0,
      limit: 10
    }
  }

  componentDidMount() {
    this.getBlockchainList()
  }

  getBlockchainList() {
    const { offset, limit } = this.state
    axiosPost('blockchain/blocks', { offset, limit }).then((res) => {
      let { chainList, length } = res
      this.setState({
        chainList,
        length
      })
    });
  }
  // fetchData() {
  //   this.getBlockchainList()
  //   setTimeout(() => {
  //     this.fetchData();
  //   }, 3000);
  // }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    var offset = (pagination.pageSize * (pagination.current - 1))
    var limit = pagination.pageSize
    this.setState({
      pagination: pager,
      offset,
      limit
    }, () => {
      this.getBlockchainList()
    });

  };

  renderBlockchainTable() {
    const { chainList } = this.state
    const columns = [
      {
        title: 'Block No',
        dataIndex: 'index',
        key: 'index',
        width: '8%',
        render: (text, record) => {
          return (
            <div>
              {/* <p>{chainList.findIndex((t) => t.id === text) + 1}</p> */}
              {text}
            </div>
          );
        }
      },
      {
        title: 'Hash',
        dataIndex: 'hash',
        key: 'hash',
        width: '45%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}><Link to={`/block-detail/${text}`} >{text.toUpperCase()}</Link></p>
            </div>
          );
        }
      },
      {
        title: 'Difficulty',
        dataIndex: 'difficulty',
        key: 'difficulty',
        width: '8%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
            </div>
          );
        }
      },
      {
        title: 'Nonce',
        dataIndex: 'nonce',
        key: 'nonce+index',
        width: '8%',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{text}</p>
            </div>
          );
        }
      },
      // {
      //   title: 'No. of Trans',
      //   dataIndex: 'transactions',
      //   key: 'transactions',
      //   width: '9%',
      //   render: (text, record) => {
      //     return (
      //       <div style={{ width: '100%', textAlign: 'center' }}>
      //         <p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{text.length}</p>
      //       </div>
      //     );
      //   }
      // },
      {
        title: 'Amount',
        dataIndex: 'transactions',
        key: 'transactions+index',
        width: '10%',
        render: (text, record) => {
          let Total = 0;
          //text.map(t => Total += parseFloat(t.amount))
          text.map((t) => {
            for (let i = 0; i < t.input.recipients.length; i++) {
              Total += parseFloat(t.outputMap[t.input.recipients[i]]);
            }
            return Total
          }
          )
          return (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <p style={{ marginBottom: 0, paddingBottom: 0, float: 'center' }}>{FormatNumber(Total)}</p>
            </div>
          );
        }
      },
      {
        title: 'Date',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (text, record) => {
          return (
            <div>
              <p style={{ marginBottom: 0, paddingBottom: 0 }}>{moment(text).format('DD-MM-YYYY hh:mm:ss a')}</p>
            </div>
          );
        }
      },
    ]
    return (
      <Table
        scroll={{ y: 510 }}

        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          position: 'bottom',
          pageSizeOptions: ['10', '30', '50', '100', '200', '500', '1000', '1500', '2000'],
          total: this.state.length,
        }}
        onChange={this.handleTableChange}
        rowKey={(record) => record.index}
        dataSource={chainList}
        columns={columns}
        showHeader={true}
        bordered
      />
    )
  }
  render() {
    return (
      <div>
        <HeaderMenu selectedKey={'1'} />
        <div style={{ margin: 30 }}>
          <h1>Blockchain List : {this.state.length}</h1>
          {
            this.renderBlockchainTable()
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const states = {
    errorMessage: state.errorMessage,
    admin: state.admin,
  };
  return states;
};

export default connect(mapStateToProps, {})(BlockchainList)